import '../scss/index.scss';
import './lozad';
import './aos';
import './header';
import './swiper';
import './dropdown';
import './scroll-to-top';

const qa = document.querySelectorAll(".js-pullDown");
function acToggle() {
  const content = this.nextElementSibling;
  content.classList.toggle("is-on");
  const qa = this;
  qa.classList.toggle('is-on');
}
for (let i = 0; i < qa.length; i++) {
  qa[i].addEventListener("click", acToggle);
}

// smooth scroll
document.addEventListener("DOMContentLoaded", function() {
  var headerHeight = document.querySelector('.header').offsetHeight;
  var adminBarHeight = document.getElementById('wpadminbar') ? document.getElementById('wpadminbar').offsetHeight : 0;
  var urlHash = location.hash;
  if (urlHash) {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    setTimeout(function() {
      var target = document.querySelector(urlHash);
      var position = target.getBoundingClientRect().top + window.scrollY - headerHeight - adminBarHeight;
      console.log(target.getBoundingClientRect().top, window.scrollY, headerHeight, adminBarHeight);
      window.scrollTo({
        top: position,
        behavior: 'smooth'
      });
    }, 100);
  }
});
